<template>
    <section class="content_pannel">
        <header class="pannel_header">
            <h3>Tabla</h3>
        </header>
        <div class="content_inner">
            <div class="grid grid_pad grid_wrap">
                <div class="grid_col grid_col_1_3 form_block">
                    <label>Categoria</label>
                    <div class="input_block">
                        <select>
                            <option>Categoria 01</option>
                            <option>Categoria 01</option>
                            <option>Categoria 01</option>
                            <option>Categoria 01</option>
                            <option>Categoria 01</option>
                        </select>
                    </div>
                </div>
                <div class="grid_col grid_col_1_3 form_block">
                    <label>Categoria</label>
                    <div class="input_block">
                        <select>
                            <option>Categoria 01</option>
                            <option>Categoria 01</option>
                            <option>Categoria 01</option>
                            <option>Categoria 01</option>
                            <option>Categoria 01</option>
                        </select>
                    </div>
                </div>
                <div class="grid_col grid_col_1_3 form_block">
                    <label>Categoria</label>
                    <div class="input_block">
                        <select>
                            <option>Categoria 01</option>
                            <option>Categoria 01</option>
                            <option>Categoria 01</option>
                            <option>Categoria 01</option>
                            <option>Categoria 01</option>
                        </select>
                    </div>
                </div>
                <div class="grid_col grid_col_1_1 form_block">
                    <VityTable 
                        :columns="columns"
                        :url="url"
                        :params="params"
                        tableFooter
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Tabla',
    props: {
        url: {
            type: String
        },
        columns: {
            type: Array
        },
        params: {
            type: Object,
            default: function(){
                return{
                    admin: 1
                }
            }
        }
    }
}
</script>