<template>
    <div class="main_wrapper">
        <transition name="modal" mode="out-in">
            <Modal v-if="modal"/>
        </transition>
        <main class="content_wrapper">
            <div class="inner">
                <section class="page_header">
                    <h2>Productos</h2>
                    <div class="page_header_nav">
                        <div class="btn med grey graphic_right" @click="modal=true">
                            <span>Agregar nuevo</span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg>
                        </div>
                    </div>
                </section>
                <Tabla v-if="url" :url="url+`works`"/>
            </div>
        </main>
    </div>
</template>

<script>
import VityTable from '@/components/VityUtils/vitytables/VityTables.vue';
import Tabla from '@/components/Tabla.vue';
import Modal from '@/components/Modal.vue';

export default {
    name: 'Index',
    components:{
        VityTable,
        Tabla,
        Modal
    },
    data: function(){
        return{
            modal: false,
            columns:[
                {
                    name: 'Titulo',
                    dbName: 'title',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Categoria',
                    dbName: 'categoryName',
                    orderable: true,
                    searchable: false
                },
                {
                    name: 'Precio',
                    center: true,
                    dbName: 'price',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Activo',
                    center: true,
                    dbName: 'active',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Accion',
                    center: true,
                    dbName: '',
                    orderable: false,
                    searchable: false,
                    template: '<a href="/productos/TPLData"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.127 22.564l-7.126 1.436 1.438-7.125 5.688 5.689zm-4.274-7.104l5.688 5.689 15.46-15.46-5.689-5.689-15.459 15.46z"/></svg></a>',
                    reference: 'url'
                },
            ],
            url: 'https://api.premiosbonobo.devfaudiovisual.com/'
        }
    }
}
</script>